import React from 'react';
import './navigation.css';
import '/src/globals/reset.css';
import RoutesLinks from '../../config/RoutesLinks';
import Icon from '../icon/Icon';
import IconTypes from '../icon/IconTypes';

const CLASS_NAME = 'navigation';
const SELECTED_OFFSET = 150;

class Navigation extends React.Component {
    constructor() {
        super();

        this.state = {
            collapsed: false,
            openMenu: false,
            currentItem: { name: RoutesLinks.HOME },
        };

        this._scrollFunction = this._scrollFunction.bind(this);
        this.onMenuAction = this.onMenuAction.bind(this);
    }

    componentDidMount() {
        if (typeof window !== `undefined`) {
            //window.onscroll = this._scrollFunction;

            document.addEventListener('scroll', this._scrollFunction, { passive: true });
        }
    }

    render() {
        const mainStyle = this.state.collapsed
            ? `${CLASS_NAME} ${CLASS_NAME}--collapsed`
            : CLASS_NAME;

        return (
            <>
                <span id={this.props.placeholderId} />
                {this.props.placeholder !== false && (
                    <div className={`${CLASS_NAME}__placeholder`} />
                )}
                <div id="navbar" className={mainStyle}>
                    <div className={`${CLASS_NAME}__content`}>
                        <div className={`${CLASS_NAME}__items ${CLASS_NAME}__desktop`}>
                            {this._renderLogo()}
                            {this._renderNavigationItem(
                                RoutesLinks.HOME,
                                this._renderHomeIcon(),
                                true
                            )}
                            {this._renderLinks()}
                        </div>
                        <div className={`${CLASS_NAME}__items ${CLASS_NAME}__mobile`}>
                            {this._renderLogo()}
                            {this._renderActionIcon()}
                        </div>
                    </div>
                    {this.state.openMenu && (
                        <div className={`${CLASS_NAME}__items ${CLASS_NAME}__mobile`}>
                            {this._renderMobileMenuItems()}
                        </div>
                    )}
                </div>
            </>
        );
    }

    _renderMobileMenuItems() {
        return (
            <div className={`${CLASS_NAME}__mobile-menu__items`}>
                {this._renderNavigationItem(RoutesLinks.HOME, this._renderHomeIcon(), true)}
                {this._renderNavigationItem(RoutesLinks.O_NAS, 'O společnosti')}
                {this._renderNavigationItem(RoutesLinks.SLUZBY, 'Služby')}
                {this._renderNavigationItem(RoutesLinks.FOTOGALERIE, 'Fotogalerie')}
                {this._renderNavigationItem(RoutesLinks.KONTAKTY, 'Kontakty')}
                {this._renderNavigationItem(RoutesLinks.MAPA, 'cesta k nám')}
            </div>
        );
    }

    _renderHomeIcon() {
        return <Icon icon={IconTypes.HOME} />;
    }

    _renderActionIcon() {
        const { openMenu } = this.state;

        return (
            <div className={`${CLASS_NAME}__icon`} onClick={this.onMenuAction}>
                <Icon icon={openMenu ? IconTypes.OpenMenu : IconTypes.ClosedMenu} />
            </div>
        );
    }

    _renderLogo() {
        return (
            <h1>
                <a className={`${CLASS_NAME}__logo-wrapper`} href={'/'} id="logo">
                    <img
                        className={`${CLASS_NAME}__image`}
                        src="/logo-removebg-preview.png"
                        alt="Armostav Místek, s.r.o."
                    />
                </a>
            </h1>
        );
    }

    _renderLinks() {
        return (
            <div className={`${CLASS_NAME}__links`}>
                {this._renderNavigationItem(RoutesLinks.O_NAS, 'O společnosti')}
                {this._renderNavigationItem(RoutesLinks.SLUZBY, 'Služby')}
                {this._renderNavigationItem(RoutesLinks.FOTOGALERIE, 'Fotogalerie')}
                {this._renderNavigationItem(RoutesLinks.KONTAKTY, 'Kontakty')}
                {this._renderNavigationItem(RoutesLinks.MAPA, 'cesta k nám')}
            </div>
        );
    }

    _renderNavigationItem(element, text, homeItem = false) {
        let styleClass = `${CLASS_NAME}__link-item`;
        styleClass +=
            element === this.state.currentItem.name ? ` ${CLASS_NAME}__link-item--selected` : '';
        styleClass += homeItem ? ` ${CLASS_NAME}__home ` : '';

        const item = (
            <span
                className={styleClass}
                onClick={event => {
                    const el = document.querySelector(`#${element}`);
                    const y = el.getBoundingClientRect().top + window.pageYOffset - 65; // fixed navigation

                    window.scrollTo({ top: y, behavior: 'smooth' });

                    this.setState({
                        openMenu: false,
                    });
                }}>
                <span className={`${CLASS_NAME}__link-text`}>{text}</span>
            </span>
        );

        if (homeItem) {
            return <div className={`${CLASS_NAME}__home`}>{item}</div>;
        }

        return item;
    }

    onMenuAction() {
        this.setState({ openMenu: !this.state.openMenu });
    }

    _scrollFunction() {
        let collapsed = false;
        if (document.body.scrollTop > 30 || document.documentElement.scrollTop > 30) {
            collapsed = true;
        }

        let menuItems = Object.values(RoutesLinks).map(element => {
            return {
                name: element,
                element: document.querySelector(`#${element}`),
            };
        });

        let currentItem = null;
        menuItems.forEach(item => {
            if (item) {
                const viewportOffset = item.element.getBoundingClientRect();
                item: item.offset = viewportOffset.top - SELECTED_OFFSET;
            }

            if (item.offset < 0) {
                if (!currentItem) {
                    currentItem = item;
                } else {
                    currentItem = currentItem > item ? currentItem : item;
                }
            }
        });

        this.setState({
            currentItem,
            collapsed,
        });
    }

    _isPathInRoute(route) {
        if (route === RoutesLinks.HOME) {
            if (this.props.location === '' || this.props.location === '/') {
                return true;
            } else {
                return false;
            }
        }

        const location = this.props.location || '';

        return location.includes(route);
    }
}

export default Navigation;
