import React from 'react';

class Icon extends React.Component {
    render() {
        return (
            <svg
                viewBox="0 0 512 512"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink">
                <g>
                    <g>
                        <g>
                            <path
                                d="M512,59.733v-51.2c0-0.109-0.058-0.2-0.062-0.308c-0.016-1.014-0.216-2.016-0.588-2.959l-0.013-0.022
				c-0.032-0.078-0.093-0.135-0.127-0.211c-0.381-0.832-0.896-1.596-1.524-2.262c-0.207-0.207-0.424-0.403-0.651-0.587
				c-0.538-0.473-1.135-0.876-1.776-1.197c-0.264-0.143-0.535-0.272-0.813-0.385c-0.949-0.38-1.958-0.584-2.98-0.602H230.4
				c-2.263,0.001-4.433,0.9-6.033,2.5L2.503,224.364L2.49,224.38c-0.686,0.71-1.242,1.535-1.643,2.437
				c-0.392,0.978-0.641,2.007-0.741,3.056C0.094,230.057,0,230.213,0,230.4v51.2c0,4.713,3.82,8.533,8.533,8.533h64
				c21.198,0.023,38.377,17.202,38.4,38.4v9.267L2.503,446.231c-1.591,1.608-2.489,3.774-2.503,6.036v51.2
				C0,508.18,3.82,512,8.533,512H281.6c1.151,0,2.289-0.234,3.347-0.688c0.115-0.049,0.197-0.155,0.31-0.209
				c0.906-0.418,1.732-0.992,2.44-1.696l0.082-0.057L458.3,330.3l51.2-51.2c1.6-1.6,2.5-3.77,2.5-6.033v-51.2
				c0-0.109-0.058-0.2-0.062-0.308c-0.016-1.014-0.216-2.016-0.588-2.959l-0.013-0.022c-0.032-0.078-0.093-0.135-0.127-0.211
				c-0.838-1.745-2.226-3.166-3.95-4.046c-0.264-0.144-0.535-0.272-0.813-0.386c-0.949-0.38-1.958-0.584-2.98-0.602h-64
				c-0.727,0.048-1.444,0.193-2.133,0.431c-0.689-0.237-1.406-0.382-2.133-0.431c-19.906-0.061-36.451-15.352-38.076-35.192
				l112.37-112.369C511.088,64.165,511.987,61.997,512,59.733z M233.933,17.067h248.933l-204.8,204.8H29.133L233.933,17.067z
				 M110.933,361.933v43.4c-0.023,21.198-17.202,38.377-38.4,38.4h-43.4L110.933,361.933z M273.067,494.933h-256V460.8h55.467
				c30.619-0.035,55.431-24.848,55.467-55.467v-76.8c-0.035-30.619-24.848-55.431-55.467-55.467H17.067v-34.133h256v34.133H217.6
				c-30.619,0.035-55.431,24.848-55.467,55.467v76.8c0.035,30.619,24.848,55.431,55.467,55.467h55.467V494.933z M494.933,269.533
				l-48.85,48.85l-155.95,163.748v-26.453L458.3,279.1l36.634-36.633V269.533z M435.2,230.4c0.727-0.048,1.444-0.193,2.133-0.431
				c0.689,0.237,1.406,0.382,2.133,0.431h43.4l-36.783,36.783l-168.142,176.55H217.6c-21.198-0.023-38.377-17.202-38.4-38.4v-76.8
				c0.023-21.198,17.202-38.377,38.4-38.4h64c2.263-0.007,4.432-0.906,6.036-2.503l95.032-95.032
				C390.267,215.156,411.397,230.361,435.2,230.4z M494.933,56.2L382.236,168.897l-0.005,0.005L290.133,261v-27.067l204.8-204.8
				V56.2z"></path>
                            <path
                                d="M216.606,401.241c-3.202-1.329-6.89-0.59-9.331,1.872c-1.559,1.636-2.442,3.799-2.475,6.059
				c0,4.713,3.821,8.533,8.533,8.533c4.713,0,8.533-3.82,8.533-8.533C221.887,405.704,219.808,402.57,216.606,401.241z"></path>
                            <path
                                d="M249.481,365.14c-2.963-0.72-6.085,0.196-8.19,2.402h0l-8.533,8.942c-3.24,3.412-3.107,8.803,0.298,12.051
				s8.796,3.128,12.052-0.268l8.533-8.942c2.105-2.206,2.873-5.368,2.015-8.294C254.798,368.106,252.444,365.86,249.481,365.14z"></path>
                            <path
                                d="M275.425,331.783l-8.533,8.942c-3.254,3.41-3.127,8.813,0.283,12.067s8.813,3.127,12.067-0.283l8.533-8.942
				c3.254-3.41,3.127-8.813-0.283-12.067C284.081,328.246,278.679,328.373,275.425,331.783z"></path>
                            <path
                                d="M309.558,296.025l-8.533,8.942c-2.358,2.471-3.016,6.11-1.672,9.25c1.344,3.14,4.431,5.176,7.847,5.175
				c2.333,0.003,4.566-0.952,6.175-2.642l8.533-8.942c3.254-3.41,3.127-8.813-0.283-12.067
				C318.215,292.488,312.812,292.615,309.558,296.025z"></path>
                            <path
                                d="M351.889,257.848c-2.968-0.719-6.095,0.204-8.198,2.419l-8.533,8.942c-2.358,2.471-3.016,6.11-1.672,9.25
				c1.344,3.14,4.431,5.176,7.847,5.175c2.333,0.003,4.566-0.952,6.175-2.642l8.533-8.942c2.114-2.205,2.888-5.371,2.031-8.302
				S354.858,258.567,351.889,257.848z"></path>
                            <path
                                d="M372.194,231.428c-3.202,1.329-5.281,4.464-5.261,7.931c-0.04,2.249,0.856,4.413,2.475,5.975
				c1.596,1.629,3.778,2.55,6.059,2.558c2.28-0.008,4.463-0.93,6.059-2.558c1.617-1.563,2.513-3.727,2.475-5.975
				c-0.018-2.262-0.904-4.431-2.475-6.059C379.084,230.838,375.396,230.098,372.194,231.428z"></path>
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}

export default Icon;
