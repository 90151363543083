import React from 'react';

import './pageMenu.css';
import RoutesLinks from '../../config/RoutesLinks';
import MapLinks from '../../config/MapLinks';
import PhotoGalleries from '../../config/PhotoGalleries';

const CLASS_NAME = 'page-menu';

class PageMenu extends React.Component {
    get isPhotoLink() {
        return this._isPathInRoute(RoutesLinks.FOTOGALERIE);
    }

    get isMapLink() {
        return this._isPathInRoute(RoutesLinks.MAPA);
    }

    render() {
        return (
            <div className={CLASS_NAME}>
                <div className={`${CLASS_NAME}__wrapper`}>
                    {this.isPhotoLink && this._renderPhotoLibraries()}
                    {this.isMapLink && this._renderMaps()}
                </div>
            </div>
        );
    }

    _isPathInRoute(route) {
        const location = this.props.location || '';

        return location.includes(route);
    }

    _renderMaps() {
        return (
            <>
                {this._renderAdditionalItem(
                    RoutesLinks.MAPA,
                    MapLinks.VEDENI,
                    'Vedení společnosti'
                )}
                {this._renderAdditionalItem(RoutesLinks.MAPA, MapLinks.PROVOZOVNA, 'Provozovna')}
            </>
        );
    }

    _renderPhotoLibraries() {
        return (
            <>
                {this._renderAdditionalItem(
                    RoutesLinks.FOTOGALERIE,
                    PhotoGalleries.PROVOZOVNA,
                    'Provozovna'
                )}
                {this._renderAdditionalItem(
                    RoutesLinks.FOTOGALERIE,
                    PhotoGalleries.VEZE,
                    'Chladící věže'
                )}
            </>
        );
    }

    _renderAdditionalItem(primeRoute, additionalRoute, text) {
        const link = `${primeRoute}/${additionalRoute}`;
        let styleClass = `${CLASS_NAME}__link-item`;

        if (this.props.location === link) {
            styleClass += ` ${CLASS_NAME}__selected`;
        }

        return (
            <a className={styleClass} href={link}>
                <div className={`${CLASS_NAME}__container`}>
                    <div className={`${CLASS_NAME}__text`}>{text}</div>
                </div>
            </a>
        );
    }
}

export default PageMenu;
