import React from 'react';
import IconTypes from './IconTypes';
import './icon.css';
import ArrowRight from './icons/ArrowRight';
import ArrowLeft from './icons/ArrowLeft';
import CoolingTower from './icons/CoolingTower';
import Bridge from './icons/Bridge';
import Monolit from './icons/Monolit';
import Pilot from './icons/Pilot';
import SubWay from './icons/SubWay';
import Tunnel from './icons/Tunnel';
import Home from './icons/Home';
import HPFirst from './icons/HPFirst';
import HPSecond from './icons/HPSecond';
import HPThird from './icons/HPThird';
import ClosedMenu from './icons/ClosedMenu';
import OpenMenu from './icons/OpenMenu';

const CLASS_NAME = 'icon';

class Icon extends React.Component {
    render() {
        return <div className={CLASS_NAME}>{this._renderIcon()}</div>;
    }

    _renderIcon() {
        switch (this.props.icon) {
            case IconTypes.RIGHT_ARROW:
                return <ArrowRight />;
            case IconTypes.LEFT_ARROW:
                return <ArrowLeft />;
            case IconTypes.COOLING_TOWER:
                return <CoolingTower />;
            case IconTypes.BRIDGE:
                return <Bridge />;
            case IconTypes.MONOLIT:
                return <Monolit />;
            case IconTypes.PILOT:
                return <Pilot />;
            case IconTypes.SUBWAY:
                return <SubWay />;
            case IconTypes.TUNNEL:
                return <Tunnel />;
            case IconTypes.HOME:
                return <Home />;
            case IconTypes.HPFirst:
                return <HPFirst />;
            case IconTypes.HPSecond:
                return <HPSecond />;
            case IconTypes.HPThird:
                return <HPThird />;
            case IconTypes.ClosedMenu:
                return <ClosedMenu />;
            case IconTypes.OpenMenu:
                return <OpenMenu />;
            default:
                return null;
        }
    }
}

export default Icon;
