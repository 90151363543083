import React from 'react';
import PhotoGallery from '../photogallery/PhotoGallery';
import Navigation from '../navigation/Navigation';
import EmptySpace from '../emptySpace/EmptySpace';
import Footer from '../footer/Footer';
import RoutesLinks from '../../config/RoutesLinks';
import PageMenu from '../pageMenu/PageMenu';
import PageTitleLine from '../pageTitleLine/PageTitleLine';
import EmptySpaceBigScreen from "../emptySpaceBigScreen/EmptySpaceBigScreen"

export default function PhotoPage(subRoute) {
    return (
        <>
            <PageTitleLine idTag={RoutesLinks.FOTOGALERIE} title={'Fotogalerie'} />
            <EmptySpace height={40} />
            <PhotoGallery />
            <EmptySpace height={100} noMobile={true} />
          <EmptySpaceBigScreen height={60} />
        </>
    );
}
