import React from 'react';

class Icon extends React.Component {
    render() {
        return (
            <svg
                viewBox="0 0 480.003 480.003"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink">
                <g>
                    <g>
                        <path
                            d="M479.706,469.842l-32.272-115.256c-15.549-55.528-23.433-112.92-23.432-170.584c5.004-2.8,9.602-6.273,13.664-10.32

c8.721-8.78,20.593-13.706,32.968-13.68h9.368v-16h-9.368c-16.62-0.046-32.568,6.559-44.288,18.344

c-8.727,8.773-20.601,13.691-32.976,13.656h-80.648c3.919-23.066,23.883-39.957,47.28-40c2.674,0,5.172-1.335,6.656-3.56

c8.498-12.782,22.835-20.457,38.184-20.44h11.16c12.113-0.023,23.896-3.951,33.6-11.2l6.4-4.8c6.931-5.178,15.348-7.984,24-8v-16

c-12.113,0.023-23.896,3.951-33.6,11.2l-6.4,4.8c-6.931,5.178-15.348,7.984-24,8h-11.2c-19.212-0.023-37.339,8.898-49.04,24.136

c-33.61,2.271-59.723,30.177-59.76,63.864c0.001,57.664-7.883,115.056-23.432,170.584l-0.336,1.2l-0.608-2.232

c-20.984-76.077-31.621-154.634-31.624-233.552c0-8.837,7.163-16,16-16h56c12.584-0.028,24.428-5.95,32-16l4.8-6.4

c4.542-6.031,11.649-9.585,19.2-9.6c39.746-0.044,71.956-32.254,72-72h-16c-0.035,30.913-25.087,55.965-56,56

c-12.584,0.028-24.428,5.95-32,16l-4.8,6.4c-4.542,6.031-11.649,9.585-19.2,9.6h-56c-14.547,0.055-27.227,9.914-30.864,24H80.722

c3.919-23.066,23.883-39.957,47.28-40h72c17.608,0.047,34.271-7.963,45.232-21.744l2.024-2.512

c7.936-9.976,19.997-15.775,32.744-15.744c17.673,0,32-14.327,32-32h-16c0,8.837-7.163,16-16,16

c-17.608-0.047-34.271,7.963-45.232,21.744l-2.024,2.512c-7.936,9.976-19.997,15.775-32.744,15.744h-72

c-35.33,0.04-63.96,28.67-64,64C64,198.919,53.363,277.477,32.378,353.554L0.29,469.874c-1.175,4.259,1.325,8.664,5.584,9.84

c0.693,0.191,1.409,0.288,2.128,0.288h464c4.418,0.001,8.001-3.581,8.001-7.999C480.003,471.272,479.904,470.545,479.706,469.842z

M312.002,192.002h81.368h14.68c0.104,8,0.392,16,0.8,24h-97.648C311.594,208.002,311.882,200.002,312.002,192.002z

M79.97,128.002h144.032c0.104,8,0.352,16,0.64,24H79.362C79.65,144.002,79.898,136.002,79.97,128.002z M18.506,464.002

L47.802,357.81c17.059-61.979,27.385-125.616,30.8-189.808h146.8c3.415,64.192,13.741,127.829,30.8,189.808l28.848,104.592

l0.448,1.6H18.506z M302.098,464.002l-21.6-78.336l7.504-26.768c11.589-41.457,19.02-83.966,22.184-126.896h99.632

c3.164,42.93,10.595,85.439,22.184,126.896l29.456,105.104H302.098z"></path>
                    </g>
                </g>
            </svg>
        );
    }
}

export default Icon;
