import React from 'react';

class Icon extends React.Component {
    render() {
        return (
            <svg
                viewBox="0 0 64 64"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink">
                <g id="outline">
                    <path d="M60,54.556V53a5.006,5.006,0,0,0-5-5H50V47a3,3,0,0,0-2-2.816V31a1,1,0,0,0-.293-.707L21.953,4.539A3,3,0,0,0,19,2H13A7.008,7.008,0,0,0,6,9V20.1A5.009,5.009,0,0,0,2,25v4a1.821,1.821,0,0,0,.2.6L5,33.333V37a1,1,0,0,0,1,1V61a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V38a1,1,0,0,0,1-1V33.333L15.8,29.6A1.821,1.821,0,0,0,16,29V25a5.009,5.009,0,0,0-4-4.9V9a1,1,0,0,1,1-1h1.586L40,33.414v10.77A3,3,0,0,0,38,47v7a4,4,0,0,0,0,8H58a3.987,3.987,0,0,0,2-7.444ZM50,50h5a3,3,0,0,1,3,3v1H56V52H54v2H50ZM42,36V33.72l4-1.333V36Zm4,2v6H42V38ZM19,4a1,1,0,1,1-1,1A1,1,0,0,1,19,4ZM10,60H8V38h2Zm1-24H7V34h4Zm.5-4h-5L5,30h8ZM8,28V27a1,1,0,0,1,2,0v1Zm6-3v3H12V27a3,3,0,0,0-6,0v1H4V25a3,3,0,0,1,3-3h4A3,3,0,0,1,14,25ZM13,6a3,3,0,0,0-3,3V20H8V9a5.006,5.006,0,0,1,5-5h3.184A2.966,2.966,0,0,0,16,5a2.966,2.966,0,0,0,.184,1Zm4.414,2H19a2.985,2.985,0,0,0,2.385-1.2L38.586,24,36,26.586Zm20,20L40,25.414l5.149,5.149L41.27,31.856ZM38,60a2,2,0,1,1,2-2A2,2,0,0,1,38,60Zm2-13a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1v3H45a1,1,0,0,0-.9.553L42.382,54H40Zm8,5v2H44.618l1-2Zm6.556,8H41.444a3.875,3.875,0,0,0,0-4H54.556a3.875,3.875,0,0,0,0,4ZM58,60a2,2,0,1,1,2-2A2,2,0,0,1,58,60Z"></path>
                </g>
            </svg>
        );
    }
}

export default Icon;
