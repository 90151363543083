import React from 'react';

class Icon extends React.Component {
    render() {
        return (
            <svg
                aria-label={'šipka'}
                viewBox="0 0 8 14"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink">
                <path d="M0.31433981,12.625 L5.78400971,7.1553301 L0.31433981,1.68566017 C0.02144661,1.39276695 0.02144661,0.91789322 0.31433981,0.625 C0.60723301,0.33210678 1.08210681,0.33210678 1.37500001,0.625 L7.9053301,7.1553301 L1.37500001,13.6856602 C1.08210681,13.9785534 0.60723301,13.9785534 0.31433981,13.6856602 C0.02144661,13.392767 0.02144661,12.9178932 0.31433981,12.625 Z" />
            </svg>
        );
    }
}

export default Icon;
