import React from 'react';

class Icon extends React.Component {
    render() {
        return (
            <svg
                viewBox="0 0 512 512"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink">
                <g>
                    <path d="m497 0h-482c-8.284 0-15 6.716-15 15v332c0 8.284 6.716 15 15 15h83.025l-95 125.968c-3.426 4.542-3.987 10.631-1.45 15.722 2.538 5.092 7.737 8.31 13.425 8.31h482c5.688 0 10.888-3.218 13.426-8.31 2.537-5.091 1.976-11.18-1.45-15.722l-95.001-125.968h83.025c8.284 0 15-6.716 15-15v-332c0-8.284-6.716-15-15-15zm-384.025 392h286.051l22.625 30h-331.301zm45.25-60h195.551c21.776 28.874-25.63-33.994 22.625 30h-240.801c12.965-17.191-19.781 26.228 22.625-30zm22.625-30 22.625-30h105.051l22.625 30zm180.15-10.242-33.024-43.789c-2.834-3.759-7.269-5.969-11.976-5.969h-120c-4.707 0-9.142 2.21-11.976 5.968l-33.024 43.79v-65.758c0-57.897 47.103-105 105-105s105 47.103 105 105zm-315.9 190.242 22.625-30h376.551l22.625 30zm436.9-150h-90.65l-.35-.464v-105.536c0-74.439-60.561-135-135-135s-135 60.561-135 135v105.536l-.35.464h-90.65v-302h452z"></path>
                </g>
            </svg>
        );
    }
}

export default Icon;
