import React from 'react';

import './footer.css';

const CLASS_NAME = 'footer';

class Footer extends React.Component {
    constructor() {
        super();
        this.state = {
            firstRender: true,
        };
    }

    componentDidMount() {
        if (typeof window !== `undefined`) {
            setTimeout(() => {
                this.setState({
                    firstRender: false,
                });
            }, 1);
        }
    }

    _getCurrentYear() {
        return new Date().getFullYear();
    }

    render() {
        const { firstRender } = this.state;

        return (
            <div className={CLASS_NAME}>
                <div className={`${CLASS_NAME}__content`}>
                    <p className={`${CLASS_NAME}__text`}>
                        Firma je zapsaná v Obchodním rejstříku Krajského soudu v Ostravě, oddíl C,
                        vložka 17969
                    </p>
                    <p className={`${CLASS_NAME}__text ${CLASS_NAME}__text--no-mobile`}>|</p>
                    <p className={`${CLASS_NAME}__text`}>
                        Copyright © 1998–{firstRender ? 2021 : this._getCurrentYear()}, Armostav
                        Místek, s.r.o.
                    </p>
                </div>
            </div>
        );
    }
}

export default Footer;
