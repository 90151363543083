import React from 'react';

class Icon extends React.Component {
    render() {
        return (
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 17v5.91h1.139v-4.137c0-.213-.008-.624-.024-.862.048.27.104.591.167.804l1.299 4.195h.923l1.29-4.195a5.93 5.93 0 0 0 .176-.804c-.016.23-.024.64-.024.87v4.129h1.131V17H4.309l-1.107 3.685a4.712 4.712 0 0 0-.144.821 7.56 7.56 0 0 0-.143-.82L1.785 17H0zm11.712 5.91v-.928H8.876V20.3h2.286v-.895H8.876v-1.486h2.78V17H7.737v5.91h3.975zM13.053 17v5.91h1.139v-4.145c0-.164-.016-.575-.032-.747.056.221.144.558.215.73l1.832 4.162h1.474V17h-1.116v3.784c0 .189.024.624.04.804a5.074 5.074 0 0 0-.223-.738L14.662 17h-1.609zm9.8 3.874c0 .788-.39 1.133-1.21 1.133-.805 0-1.203-.337-1.203-1.133V17h-1.155v4.096c0 1.288 1.115 1.904 2.342 1.904 1.21 0 2.373-.616 2.373-1.904V17h-1.147v3.874zM0 2h24v3H0zm0 8h24v3H0z"></path>
            </svg>
        );
    }
}

export default Icon;
