import React from 'react';

class Icon extends React.Component {
    render() {
        return (
            <svg
                viewBox="0 0 435.185 435.185"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink">
                <g>
                    <path
                        d="M431.185,218.187c2.209,0,4-1.791,4-4c0-2.209-1.791-4-4-4h-22.58v-11.006c7.27,1.632,14.825,2.499,22.58,2.499

c2.209,0,4-1.791,4-4s-1.791-4-4-4c-52.4,0-95.029-42.63-95.029-95.029c0-2.209-1.791-4-4-4h-3.768V81.176c0-2.209-1.791-4-4-4

c-2.209,0-4,1.791-4,4v13.476h-3.767c-2.209,0-4,1.791-4,4c0,52.399-42.63,95.029-95.029,95.029

c-52.399,0-95.029-42.63-95.029-95.029c0-2.209-1.791-4-4-4h-3.767V81.176c0-2.209-1.791-4-4-4s-4,1.791-4,4v13.476h-3.767

c-2.209,0-4,1.791-4,4c0,52.399-42.63,95.029-95.029,95.029c-2.209,0-4,1.791-4,4s1.791,4,4,4c7.759,0,15.317-0.868,22.59-2.501

v11.008H4c-2.209,0-4,1.791-4,4c0,2.209,1.791,4,4,4h95.029v8.5H4c-2.209,0-4,1.791-4,4c0,2.209,1.791,4,4,4h95.029v52.2h-2.791

c-2.209,0-4,1.791-4,4v28.669c-3.642,0.079-7.308-1.199-10.146-3.857c-8.58-8.037-22.022-8.037-30.603-0.001

c-5.514,5.166-14.152,5.164-19.665,0c-1.612-1.51-4.143-1.427-5.654,0.186c-1.51,1.612-1.427,4.144,0.185,5.654

c8.58,8.036,22.022,8.035,30.603-0.001c5.513-5.164,14.151-5.165,19.665,0.001c8.581,8.036,22.023,8.035,30.603-0.001

c5.514-5.164,14.151-5.165,19.664,0.001c8.582,8.036,22.023,8.035,30.603-0.001c5.514-5.164,14.151-5.165,19.665,0.001

c8.581,8.036,22.023,8.035,30.602-0.001c5.513-5.163,14.151-5.166,19.665,0.001c8.582,8.035,22.022,8.036,30.603-0.001

c5.514-5.165,14.151-5.164,19.665,0.001c8.581,8.036,22.023,8.035,30.602-0.001c5.515-5.165,14.152-5.164,19.665,0.001

c8.581,8.036,22.023,8.035,30.603-0.001c5.515-5.165,14.152-5.164,19.665,0.001c4.29,4.018,9.796,6.026,15.302,6.026

c5.505,0,11.011-2.009,15.301-6.026c1.612-1.511,1.695-4.042,0.185-5.654c-1.51-1.611-4.041-1.693-5.654-0.186

c-5.512,5.166-14.149,5.165-19.665,0.001c-8.579-8.037-22.023-8.038-30.603-0.001c-2.838,2.66-6.504,3.939-10.146,3.86v-28.671

c0-2.209-1.791-4-4-4h-2.792v-52.2h95.029c2.209,0,4-1.791,4-4c0-2.209-1.791-4-4-4h-95.029v-8.5H431.185z M400.604,210.187h-18.59

v-21.017c5.876,3.205,12.096,5.855,18.59,7.877V210.187z M374.015,184.321v25.866h-18.592v-41.792

C360.961,174.406,367.201,179.759,374.015,184.321z M347.423,158.574v51.613h-11.267v-71.738

C339.162,145.602,342.954,152.347,347.423,158.574z M312.621,226.687H122.563v-8.5h190.058V226.687z M107.029,102.651h7.534

v184.233h-7.534V102.651z M248.178,210.187v-13.142c6.494-2.022,12.714-4.673,18.59-7.878v21.02H248.178z M240.178,210.187h-18.59

v-8.592c6.36-0.244,12.573-1.064,18.59-2.415V210.187z M213.588,210.187h-18.58v-11.007c6.013,1.351,12.224,2.171,18.58,2.415

V210.187z M187.008,210.187h-18.59v-21.02c5.876,3.205,12.096,5.855,18.59,7.879V210.187z M320.621,102.651h7.534v184.233h-7.534

V102.651z M312.621,210.187h-11.264v-51.618c4.467-6.227,8.258-12.969,11.264-20.12V210.187z M293.357,168.391v41.797h-18.59

v-25.87C281.581,179.755,287.82,174.402,293.357,168.391z M160.417,184.318v25.869h-18.591v-41.797

C147.364,174.402,153.604,179.756,160.417,184.318z M133.826,158.568v51.619h-11.263v-71.738

C125.569,145.6,129.36,152.342,133.826,158.568z M79.77,168.386v41.802H61.18v-25.873C67.993,179.751,74.233,174.397,79.77,168.386

z M34.59,197.045c6.494-2.023,12.714-4.674,18.59-7.88v21.022H34.59V197.045z M87.77,210.187v-51.625

c4.465-6.225,8.254-12.965,11.259-20.113v71.738H87.77z M101.756,315.698c-0.481,0.451-0.994,0.848-1.519,1.22v-22.03h21.12v15.204

C114.516,308.766,107.17,310.629,101.756,315.698z M333.429,315.699c-5.412-5.07-12.759-6.935-19.601-5.607v-15.204h21.119v22.031

C334.422,316.547,333.91,316.15,333.429,315.699z M309.828,286.888c-2.209,0-4,1.791-4,4v22.468

c-1.048,0.689-2.059,1.459-3.002,2.343c-5.512,5.166-14.15,5.165-19.665,0.001c-8.578-8.037-22.022-8.038-30.603-0.001

c-5.514,5.166-14.151,5.163-19.665,0.001c-8.579-8.038-22.022-8.036-30.602-0.001c-5.514,5.166-14.152,5.163-19.665,0.001

c-8.579-8.037-22.022-8.037-30.603-0.001c-5.513,5.164-14.151,5.164-19.665,0.001c-0.943-0.884-1.954-1.653-3.001-2.342v-22.47

c0-2.209-1.791-4-4-4h-2.794v-52.2h190.058v52.2H309.828z"></path>

                    <path
                        d="M403.361,346.142c-5.512,5.165-14.149,5.166-19.665,0.001c-8.579-8.038-22.023-8.037-30.603-0.001

c-5.512,5.165-14.149,5.166-19.665,0.001c-8.578-8.038-22.023-8.037-30.603-0.001c-5.512,5.165-14.15,5.166-19.665,0.001

c-8.578-8.038-22.022-8.037-30.603-0.001c-5.514,5.166-14.151,5.163-19.665,0.001c-8.579-8.038-22.022-8.035-30.602-0.001

c-5.514,5.165-14.152,5.164-19.665,0.001c-8.579-8.037-22.022-8.036-30.603-0.001c-5.513,5.164-14.151,5.163-19.665,0.001

c-8.579-8.037-22.021-8.036-30.603-0.001c-5.513,5.164-14.151,5.163-19.665,0.001c-8.58-8.037-22.022-8.036-30.603-0.001

c-5.514,5.165-14.152,5.165-19.665,0c-1.612-1.51-4.143-1.427-5.654,0.186c-1.51,1.612-1.427,4.143,0.185,5.654

c8.58,8.035,22.022,8.036,30.603-0.001c5.513-5.163,14.151-5.166,19.665,0.001c8.581,8.035,22.023,8.036,30.603-0.001

c5.514-5.165,14.151-5.164,19.664,0.001c8.582,8.035,22.023,8.036,30.603-0.001c5.514-5.163,14.151-5.166,19.665,0.001

c8.581,8.035,22.023,8.036,30.602-0.001c5.513-5.164,14.151-5.165,19.665,0.001c8.581,8.034,22.021,8.037,30.603-0.001

c5.514-5.164,14.151-5.165,19.665,0.001c8.581,8.036,22.023,8.035,30.602-0.001c5.515-5.164,14.152-5.165,19.665,0.001

c8.581,8.036,22.023,8.035,30.603-0.001c5.515-5.164,14.152-5.165,19.665,0.001c4.29,4.017,9.796,6.026,15.302,6.026

c5.505,0,11.011-2.009,15.301-6.026c1.612-1.511,1.695-4.042,0.185-5.654C407.506,344.717,404.975,344.635,403.361,346.142z"></path>
                </g>

                <g></g>
            </svg>
        );
    }
}

export default Icon;
