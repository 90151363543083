import React from 'react';

import PhotoGalleries from '../../config/PhotoGalleries';

import Carousel, { arrowsPlugin, Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import { StaticImage } from 'gatsby-plugin-image';
import 'react-image-gallery/styles/css/image-gallery.css';
import './photoGallery.css';
import Arrow from '../arrow/Arrow';

const CLASS_NAME = 'photo-gallery';
const GALERIES_OPTION = Object.values(PhotoGalleries);

class PhotoGallery extends React.Component {
    constructor() {
        super();

        this._setGallery = this._setGallery.bind(this);
        this.onchange = this.onchange.bind(this);
        this.onThumbnailChange = this.onThumbnailChange.bind(this);

        this.state = {
            gallery: PhotoGalleries.OHYBARNA,
            value: 0,
            firstRender: true,
            slides: [
                <StaticImage
                    key={1}
                    src="../../../static/gallery/ohybarna/1.JPG"
                    alt={'ohybarna'}
                    loading={'lazy'}
                />,
                <StaticImage
                    key={2}
                    src="../../../static/gallery/ohybarna/4.JPG"
                    alt={'ohybarna'}
                    loading={'lazy'}
                />,
                <StaticImage
                    key={3}
                    src="../../../static/gallery/ohybarna/5.JPG"
                    alt={'ohybarna'}
                    loading={'lazy'}
                />,
                <StaticImage
                    key={4}
                    src="../../../static/gallery/ohybarna/6.JPG"
                    alt={'ohybarna'}
                    loading={'lazy'}
                />,
                <StaticImage
                    key={5}
                    src="../../../static/gallery/ohybarna/7.JPG"
                    alt={'ohybarna'}
                    loading={'lazy'}
                />,
                <StaticImage
                    key={6}
                    src="../../../static/gallery/ohybarna/8.JPG"
                    alt={'ohybarna'}
                />,
                <StaticImage
                    key={7}
                    src="../../../static/gallery/ohybarna/9.JPG"
                    alt={'ohybarna'}
                />,
                <StaticImage
                    key={8}
                    src="../../../static/gallery/ohybarna/10.JPG"
                    alt={'ohybarna'}
                />,
                <StaticImage
                    key={9}
                    src="../../../static/gallery/ohybarna/11.JPG"
                    alt={'ohybarna'}
                />,
                <StaticImage
                    key={10}
                    src="../../../static/gallery/ohybarna/12.JPG"
                    alt={'ohybarna'}
                />,
                <StaticImage
                    key={11}
                    src="../../../static/gallery/ohybarna/13.JPG"
                    alt={'ohybarna'}
                />,
                <StaticImage
                    key={12}
                    src="../../../static/gallery/ohybarna/14.JPG"
                    alt={'ohybarna'}
                />,
                <StaticImage
                    key={13}
                    src="../../../static/gallery/ohybarna/16.JPG"
                    alt={'ohybarna'}
                />,
                <StaticImage
                    key={14}
                    src="../../../static/gallery/ohybarna/17.JPG"
                    alt={'ohybarna'}
                />,
                <StaticImage
                    key={15}
                    src="../../../static/gallery/ohybarna/18.JPG"
                    alt={'ohybarna'}
                />,
                <StaticImage
                    key={16}
                    src="../../../static/gallery/ohybarna/19.JPG"
                    alt={'ohybarna'}
                />,
                <StaticImage
                    key={17}
                    src="../../../static/gallery/ohybarna/20.JPG"
                    alt={'ohybarna'}
                />,
            ],
        };

        this.state.thumbnails = this.state.slides.map((slide, index) =>
            this._generateThumbnail(slide, index)
        );
    }

    componentDidMount() {
        if (typeof window !== `undefined`) {
            setTimeout(() => {
                this.setState({
                    firstRender: false,
                });
            }, 1);
        }
    }

    render() {
        return (
            <div className={CLASS_NAME}>
                {this._renderMenu()}
                {this._renderGallery()}
            </div>
        );
    }

    _generateThumbnail(slide, index) {
        /*slide.props.sizes = '100px';*/
        return (
            <div
                className={`${CLASS_NAME}__thumbnail`}
                key={index}
                onClick={() => {
                    this.onchange(index);
                }}>
                {slide}
            </div>
        );
    }

    _renderMenu() {
        return (
            <div className={`${CLASS_NAME}__menu`}>
                {GALERIES_OPTION.map(item => {
                    let styleClass = `${CLASS_NAME}__item`;
                    styleClass +=
                        this.state.gallery === item ? ` ${CLASS_NAME}__item--selected` : '';

                    return (
                        <span
                            key={item}
                            className={styleClass}
                            onClick={() => this._setGallery(item)}>
                            {item}
                        </span>
                    );
                })}
            </div>
        );
    }

    _setGallery(gallery) {
        this.setState({ gallery });
    }

    _renderCarouselGallery() {
        return (
            <div>
                <Carousel
                    value={this.state.value}
                    slides={this.state.slides}
                    onChange={this.onchange}
                    plugins={[
                        {
                            resolve: arrowsPlugin,
                        },
                    ]}
                />
                {
                    <Dots
                        number={5}
                        thumbnails={this._getThumbnailItems()}
                        value={this.state.value - this._getThumbnailOffset()}
                        onChange={this.onThumbnailChange}
                    />
                }
            </div>
        );
    }

    onchange(value) {
        if (value < 0) {
            value = this.state.slides.length - 1;
        } else if (value > this.state.slides.length - 1) {
            value = 0;
        }

        this.setState({ value });
    }

    _getThumbnailOffset() {
        const { length } = this.state.thumbnails;
        const { value } = this.state;

        if (value < 2) {
            return 0;
        }
        if (value + 5 <= length) {
            return value - 2;
        } else {
            return length - 5;
        }
    }

    _getThumbnailItems() {
        const { length } = this.state.thumbnails;
        const { value, thumbnails } = this.state;

        if (value < 2) {
            return thumbnails.slice(0, 5);
        }
        if (value + 3 <= length) {
            const offset = this._getThumbnailOffset();
            return thumbnails.slice(offset, offset + 5);
        } else {
            return thumbnails.slice(length - 5);
        }
    }

    _renderArrow(rotation) {
        return (
            <div onClick={this.onArrowClick} className={`${CLASS_NAME}__arrow`}>
                <Arrow rotation={rotation} color={'black'} />
            </div>
        );
    }

    onThumbnailChange(value) {
        this.onchange(value + this._getThumbnailOffset());
    }

    _renderPlaceholder() {
        return <div>{this.state.slides[0]}</div>;
    }

    _renderGallery() {
        const { gallery, firstRender } = this.state;
        return (
            <>
                <div className={`${CLASS_NAME}__gallery-wrapper`}>
                    {firstRender ? this._renderPlaceholder() : this._renderCarouselGallery()}
                </div>
            </>
        );
    }
}

export default PhotoGallery;
