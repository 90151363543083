export default Object.freeze({
    RIGHT_ARROW: 'right_arrow',
    LEFT_ARROW: 'left_arrow',
    COOLING_TOWER: 'cooling_tower',
    BRIDGE: 'bridge',
    MONOLIT: 'monolit',
    PILOT: 'pilot',
    SUBWAY: 'sub_way',
    TUNNEL: 'tunnel',
    HOME: 'home',
    HPFirst: 'first',
    HPSecond: 'secnond',
    HPThird: 'third',
    ClosedMenu: 'closed_menu',
    OpenMenu: 'open_menu',
});
