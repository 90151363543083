import React from 'react';

class Icon extends React.Component {
    render() {
        return (
            <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 198.139 198.139">
                <path
                    d="M22.333,107.626c-1.657,0-3-1.343-3-3V89.814H3c-1.657,0-3-1.343-3-3s1.343-3,3-3h19.333c1.657,0,3,1.343,3,3v17.813

C25.333,106.283,23.99,107.626,22.333,107.626z"></path>

                <path
                    d="M195.139,178.991H99.667c-1.657,0-3-1.343-3-3v-14.813H80.333c-1.657,0-3-1.343-3-3c0-0.014,0-0.028,0-0.042

c0-0.014,0-0.028,0-0.042v-14.83l-16.317,0.018c-0.238,0.013-0.482-0.027-0.709-0.08c-0.002-0.003-0.005-0.002-0.007-0.002

c-0.002,0-0.005-0.002-0.007-0.002c-1.162-0.28-2.06-1.237-2.254-2.432l0,0c0-0.003-0.001-0.006-0.001-0.009c0,0,0,0,0-0.001

c0-0.003-0.001-0.005-0.001-0.008c0-0.001,0-0.001,0-0.001c0-0.003-0.001-0.006-0.001-0.009v-0.001c0-0.003-0.001-0.006-0.001-0.008

c0-0.001,0-0.001,0-0.002c0-0.002-0.001-0.005-0.001-0.008c0,0,0-0.001,0-0.002c0-0.002-0.001-0.005-0.001-0.007

c0-0.001,0-0.002,0-0.002c0-0.003,0-0.005-0.001-0.008c0,0,0-0.001,0-0.002c0-0.003,0-0.005-0.001-0.007c0-0.001,0-0.002,0-0.003

c0-0.002,0-0.004,0-0.007c0-0.001,0-0.002,0-0.003c0-0.002,0-0.004-0.001-0.006c0-0.001,0-0.002,0-0.003c0-0.003,0-0.004,0-0.007

c0-0.001,0-0.002,0-0.003c0-0.002,0-0.004-0.001-0.006c0-0.001,0-0.003,0-0.004c0-0.002,0-0.004,0-0.006c0-0.001,0-0.003,0-0.004

c0-0.002,0-0.003,0-0.005c0-0.002,0-0.003,0-0.005c0-0.001,0-0.003-0.001-0.005c0-0.001,0-0.003,0-0.005c0-0.001,0-0.003,0-0.004

c0-0.002,0-0.004,0-0.005c0-0.002,0-0.003,0-0.005s0-0.004,0-0.005c0-0.002,0-0.003,0-0.005c0-0.001,0-0.003,0-0.005

c0-0.001,0-0.003,0-0.004c0-0.002,0-0.004,0-0.006c0,0,0-0.002,0-0.004s0-0.004,0-0.005c0-0.002,0-0.003,0-0.004

c0-0.002,0-0.004,0-0.006c0-0.002,0-0.003,0-0.004c0-0.002,0-0.004,0-0.006c0-0.001,0-0.003,0-0.004c0-0.002,0-0.004,0-0.006

c0-0.001,0-0.002,0-0.003c0-0.002,0-0.005,0-0.007c0-0.001,0-0.002,0-0.003c0-0.002,0-0.004,0-0.007c0-0.001,0-0.002,0-0.003

c0-0.002,0-0.004,0-0.006c0-0.001,0-0.002,0-0.004s0-0.004,0-0.006c0-0.001,0-0.002,0-0.003c0-0.003,0-0.005,0-0.007

c0-0.001,0-0.002,0-0.003c0-0.002,0-0.004,0-0.007c0-0.001,0-0.002,0-0.003c0-0.002,0-0.004,0-0.006c0-0.001,0-0.002,0-0.004

c0-0.002,0-0.004,0-0.006c0-0.001,0-0.002,0-0.003c0-0.003,0-0.005,0-0.007c0-0.001,0-0.002,0-0.003c0-0.002,0-0.005,0-0.007

c0-0.001,0-0.002,0-0.003c0-0.002,0-0.004,0-0.006c0-0.002,0-0.003,0-0.004c0-0.002,0-0.004,0-0.006c0-0.001,0-0.003,0-0.004

c0-0.002,0-0.004,0-0.006c0-0.001,0-0.002,0-0.004s0-0.004,0-0.006c0-0.001,0-0.002,0-0.003c0-0.002,0-0.004,0-0.006

s0-0.003,0-0.004c0-0.002,0-0.004,0-0.006c0-0.001,0-0.003,0-0.004c0-0.002,0-0.004,0-0.006c0-0.001,0-0.003,0-0.004

c0-0.002,0-0.004,0-0.006c0-0.001,0-0.003,0-0.004c0-0.002,0-0.004,0-0.005c0-0.002,0-0.003,0-0.005s0-0.003,0-0.005

s0-0.003,0-0.005s0-0.003,0-0.005c0-0.001,0-0.003,0-0.005c0-0.001,0-0.003,0-0.004c0-0.002,0-0.004,0-0.006

c0-0.001,0-0.003,0-0.004c0-0.002,0-0.004,0-0.006c0-0.001,0-0.003,0-0.004c0-0.002,0-0.004,0-0.006c0-0.001,0-0.002,0-0.003

c0-0.003,0-0.005,0-0.007c0-0.001,0-0.002,0-0.003c0-0.002,0-0.005,0-0.007c0-0.001,0-0.002,0-0.003c0-0.002,0-0.003,0-0.005

c0-0.001,0-0.001,0-0.002v-0.001V125.47H41.667c-1.657,0-3-1.343-3-3c0-0.005,0-0.011,0-0.016s0-0.011,0-0.016v-14.83l-16.331,0.018

c-0.001,0-0.002,0-0.003,0c-1.656,0-2.998-1.341-3-2.997s1.34-3.001,2.997-3.003l19.333-0.021c0.001,0,0.002,0,0.002,0

c0.796,0,1.559,0.315,2.121,0.878c0.563,0.563,0.88,1.326,0.88,2.122v14.864H61c1.657,0,3,1.343,3,3v14.81l16.331-0.018

c0.001,0,0.002,0,0.003,0c0.795,0,1.558,0.315,2.12,0.878c0.563,0.563,0.88,1.326,0.88,2.122v14.917h16.333c1.657,0,3,1.343,3,3

v14.813h13.472V75.481H3c-1.657,0-3-1.343-3-3s1.343-3,3-3h137.056v-6.333h-6.811c-5.698,0-10.333-4.636-10.333-10.334V29.481

c0-5.697,4.636-10.333,10.333-10.333h47.789c5.698,0,10.333,4.636,10.333,10.333v23.333c0,5.698-4.636,10.334-10.333,10.334h-6.811

v6.333h20.917c1.657,0,3,1.343,3,3v103.511C198.139,177.649,196.796,178.991,195.139,178.991z M183.306,172.991h8.833V75.481h-70

v97.511h8.833v-71.344c0-1.657,1.343-3,3-3h46.333c1.657,0,3,1.343,3,3V172.991z M160.139,172.991h17.167v-68.344h-17.167V172.991z

M136.972,172.991h17.167v-68.344h-17.167V172.991z M146.056,69.481h22.167v-6.333h-22.167V69.481z M171.222,57.148h9.811

c2.39,0,4.333-1.944,4.333-4.334V29.481c0-2.39-1.944-4.333-4.333-4.333h-47.789c-2.39,0-4.333,1.943-4.333,4.333v23.333

c0,2.39,1.944,4.334,4.333,4.334H171.222z M157,51.974c-0.354,0-0.713-0.063-1.063-0.196c-0.056-0.021-0.112-0.044-0.167-0.068l0,0

c-0.002-0.001-0.004-0.002-0.007-0.004c-0.324-0.146-0.61-0.344-0.853-0.579c0.001,0.001-0.001-0.003-0.003-0.004

c-0.251-0.244-0.463-0.536-0.62-0.87c-0.001-0.003-0.002-0.005-0.003-0.006c-0.014-0.028-0.026-0.058-0.039-0.087l-3.911-9.007

l-3.652,8.953c-0.626,1.534-2.379,2.271-3.911,1.645c-1.534-0.625-2.271-2.376-1.645-3.91l6.343-15.552

c0.456-1.118,1.537-1.854,2.744-1.867c0.011,0,0.023,0,0.034,0c1.194,0,2.275,0.708,2.752,1.806l3.914,9.015l3.652-8.953

c0.456-1.118,1.537-1.854,2.744-1.867c1.195-0.032,2.305,0.697,2.786,1.806l6.752,15.552c0.66,1.52-0.037,3.286-1.557,3.946

c-1.518,0.661-3.286-0.037-3.947-1.558l-3.914-9.015l-3.65,8.948c-0.009,0.022-0.018,0.044-0.027,0.065

c-0.001,0.002-0.001,0.003-0.002,0.005c0,0.001-0.001,0.002-0.001,0.003c-0.148,0.339-0.352,0.638-0.596,0.889

c0,0.001-0.001,0.002-0.003,0.003c-0.265,0.272-0.585,0.498-0.956,0.659c-0.382,0.166-0.779,0.246-1.171,0.249

C157.016,51.974,157.008,51.974,157,51.974z"></path>

                <g></g>
            </svg>
        );
    }
}

export default Icon;
