import React from 'react';

import './arrow.css';

const CLASS_NAME = 'arrow';

class Arrow extends React.Component {
    render() {
        const rotation = this.props.rotation || 'left';
        const color = this.props.color || 'white';
        const arrowClass = `${CLASS_NAME}__${color}`;

        if (rotation === 'right') {
            return this._renderRight(arrowClass);
        } else if (rotation === 'left') {
            return this._renderLeft(arrowClass);
        }
    }

    _renderRight(arrowClass = '') {
        arrowClass += ` ${CLASS_NAME} ${CLASS_NAME}__right`;
        return (
            <svg
                className={arrowClass}
                aria-label={'šipka'}
                viewBox="0 0 8 14"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink">
                <path d="M0.31433981,12.625 L5.78400971,7.1553301 L0.31433981,1.68566017 C0.02144661,1.39276695 0.02144661,0.91789322 0.31433981,0.625 C0.60723301,0.33210678 1.08210681,0.33210678 1.37500001,0.625 L7.9053301,7.1553301 L1.37500001,13.6856602 C1.08210681,13.9785534 0.60723301,13.9785534 0.31433981,13.6856602 C0.02144661,13.392767 0.02144661,12.9178932 0.31433981,12.625 Z" />
            </svg>
        );
    }

    _renderLeft(arrowClass = '') {
        arrowClass += ` ${CLASS_NAME} ${CLASS_NAME}__left`;
        return (
            <svg
                className={arrowClass}
                aria-label={'šipka'}
                viewBox="0 0 8 14"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink">
                <path d="M7.6856602,12.625 C7.9785534,12.9178932 7.9785534,13.392767 7.6856602,13.6856602 C7.392767,13.9785534 6.9178932,13.9785534 6.625,13.6856602 L0.09466991,7.1553301 L6.625,0.625 C6.9178932,0.33210678 7.392767,0.33210678 7.6856602,0.625 C7.9785534,0.91789322 7.9785534,1.39276695 7.6856602,1.68566017 L2.2159903,7.1553301 L7.6856602,12.625 Z" />
            </svg>
        );
    }
}

export default Arrow;
