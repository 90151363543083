import React from 'react';

class Icon extends React.Component {
    render() {
        return (
            <svg
                aria-label={'šipka'}
                viewBox="0 0 8 14"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink">
                <path d="M7.6856602,12.625 C7.9785534,12.9178932 7.9785534,13.392767 7.6856602,13.6856602 C7.392767,13.9785534 6.9178932,13.9785534 6.625,13.6856602 L0.09466991,7.1553301 L6.625,0.625 C6.9178932,0.33210678 7.392767,0.33210678 7.6856602,0.625 C7.9785534,0.91789322 7.9785534,1.39276695 7.6856602,1.68566017 L2.2159903,7.1553301 L7.6856602,12.625 Z" />
            </svg>
        );
    }
}

export default Icon;
